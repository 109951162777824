import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";

const Nav = styled.div`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #495057;
  box-shadow: 2px 2px 2px #495057;
  padding-right: 1rem;
  font-size: 1rem;
  font-weight: 400;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #fff;
  box-shadow: 0.063rem 0 1.25rem 0 #8690a3;
  transition: all 0.5s ease;
  width: 267px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = (sasPartners) => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  let partnerUrls = sasPartners?.sasPartners?.map((sas) => {
    let sasPartnerUrl = {
      title: `${sas?.name}`,
      path: `sas/${sas.id}`,
      icon: <AiIcons.AiOutlineBarChart />,
      cName: "sub-nav",
    };
    return sasPartnerUrl;
  });
  const SidebarData = [
    {
      title: "SALES DASHBOARD",
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          title: "Daily Report",
          path: "/sales-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        {
          title: "Underwriter Report",
          path: "/underwriter-sales-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        {
          title: "Service providers Report",
          path: "/service-providers-sales-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        {
          title: "Third parties Report",
          path: "/third-parties-sales-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        {
          title: "Affiliate Reporting",
          path: "/affliate-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        {
          title: "Annual Report",
          path: "/annual-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        {
          title: "Channels Report",
          path: "/channels-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "USERS DASHBOARD",
      path: "/user-stats",
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
    },
    {
      title: "POLICIES DASHBOARD",
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          title: "Motor",
          path: "/motor-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "SaaS DASHBOARD",
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          title: "Britam-BYM",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
          subNavChildren: [
            {
              title: "Sales Report",
              path: "/bym-report",
              icon: <AiIcons.AiOutlineBarChart />,
              cName: "sub-nav",
            },
            {
              title: "Agents Report",
              path: "/bym-report/agents-report",
              icon: <AiIcons.AiOutlineBarChart />,
              cName: "sub-nav",
            },
            {
              title: "Health Policy Breakdown",
              path: "/bym-report/health-policy-breakdown",
              icon: <AiIcons.AiOutlineBarChart />,
              cName: "sub-nav",
            },
          ],
        },
        {
          title: "Britam-Cooperative Bank",
          path: "/britam-banca-report",
          icon: <AiIcons.AiOutlineBarChart />,
          cName: "sub-nav",
        },
        ...partnerUrls,
      ],
    },
    {
      title: "SITE TRACK DASHBOARD",
      path: "/site-track-stats",
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
    },
  ];

  useEffect(() => {
    // fetchThirdParties();
  }, []);
  return (
    <>
      <IconContext.Provider value={{ color: "#32c0da" }}>
        <Nav>
          <NavIcon to="#">
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
          <div>
            {sessionStorage.getItem("userName") + " "}
            <Link to="/login"> [LOGOUT] </Link>
          </div>
          {/* <h2>Logout</h2> */}
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
