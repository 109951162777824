import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, createHashRouter } from "react-router-dom";
import Login from "./auth/Login";
import BaseLayout from "./shared/base-layout/BaseLayout";
import Loader from "./components/Loader";
import { makePostRequest } from "./api/ApiHandler";
import { BritamBancaModuleID, BymModuleID } from "./enums/moduleID.enums";

const LazySalesReport = lazy(() => import("./components/sales/Sales"));

const LazyUnderwriterSalesReport = lazy(
  () => import("./components/underwriter-sales/UnderwriterSales")
);

const LazyServiceProvidersSalesReport = lazy(
  () => import("./components/service-providers/ServiceProviders")
);

const LazyThirdPartySalesReport = lazy(
  () => import("./components/third-party-sales/ThirdPartySales")
);

const LazyAffliateReport = lazy(
  () => import("./components/affliate-report/AffliateReport")
);

const LazyRevenueReport = lazy(
  () => import("./components/revenue-report/RevenueReport")
);

const LazyMotorReport = lazy(
  () => import("./components/policies-dashboard/motor/MotorDashboard")
);

const LazyBYMReport = lazy(() => import("./components/saas/bym/BymSales"));
const LazyHealthPolicyBreakDown = lazy(
  () => import("./components/saas/bym/HealthPolicyBreakdown")
);
const LazyBritamBancaReport = lazy(
  () => import("./components/saas/britam-banca/BritamBancaSales")
);

const LazyNBKReport = lazy(() => import("./components/saas/mtek-sas/SASSales"));
const LazyChannelsReport = lazy(() => import("./components/channels/Channels"));
const UserStatisticsModule = lazy(
  () => import("./components/user-statistics/UserStatistics")
);

const LazyBYMAgentsReport = lazy(
  () => import("./components/saas/bym/agents-report/AgentsReport")
);

const LazyBYMRegionalReport = lazy(
  () => import("./components/saas/bym/RegionalReport")
);

const LazySiteTrackModule = lazy(
  () => import("./components/site-track/SitetrackDashboard")
);

// const router = createHashRouter([]);

function App() {
  let isAdmin = JSON.parse(sessionStorage.getItem("admin")!) === true;
  const partnersRoutes: any[] =
    JSON.parse(sessionStorage.getItem("sasPartners")!) ?? [];
  const [sasPartners, setSasPartners] = useState(
    partnersRoutes?.length > 0 ? partnersRoutes : []
  );

  const fetchThirdParties = async () => {
    const apiResponse = await makePostRequest(
      `${process.env.REACT_APP_CORE_API_URL}/listThirdParties`,
      {
        $and: [
          {
            _id: { $ne: BymModuleID },
          },
          {
            _id: { $ne: BritamBancaModuleID },
          },
        ],
        "segments.key": {
          $in: ["saas"],
        },
        fields: ["_id", "name", "segments"],
      }
    );
    if (apiResponse?.Status == 200) {
      let sasPartners = apiResponse.Payload?.map((sas) => {
        let route = {
          name: sas?.name,
          id: sas?._id,
        };
        return route;
      });
      sessionStorage.setItem("sasPartners", JSON.stringify(sasPartners));
      setSasPartners(sasPartners);
    }
  };
  const [isLoggedIn, setIsLoggedIn] = useState(isAdmin);

  useEffect(() => {
    if (!isAdmin) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      fetchThirdParties();
    }
  }, [sessionStorage.getItem("admin")]);

  return (
    <>
      <Routes>
        {!isLoggedIn && (
          <Route
            path="/login"
            element={
              <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
            }
          />
        )}
        {isLoggedIn && (
          <Route
            path="/"
            element={
              <BaseLayout isLoggedIn={isLoggedIn} sasPartners={sasPartners} />
            }
          >
            <Route index element={<LazySalesReport />} />
            <Route
              path="sales-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazySalesReport />
                </Suspense>
              }
            />
            <Route
              path="user-stats"
              element={
                <Suspense fallback={<Loader />}>
                  <UserStatisticsModule />
                </Suspense>
              }
            />
            <Route
              path="site-track-stats"
              element={
                <Suspense fallback={<Loader />}>
                  <LazySiteTrackModule />
                </Suspense>
              }
            />
            <Route
              path="site-track-stats"
              element={
                <Suspense fallback={<Loader />}>
                  <LazySiteTrackModule />
                </Suspense>
              }
            />
            <Route
              path="underwriter-sales-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyUnderwriterSalesReport />
                </Suspense>
              }
            />
            <Route
              path="service-providers-sales-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyServiceProvidersSalesReport />
                </Suspense>
              }
            />
            <Route
              path="third-parties-sales-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyThirdPartySalesReport />
                </Suspense>
              }
            />
            <Route
              path="/affliate-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyAffliateReport />
                </Suspense>
              }
            />
            <Route
              path="annual-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyRevenueReport />
                </Suspense>
              }
            />
            <Route
              path="motor-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyMotorReport />
                </Suspense>
              }
            />
            <Route path="bym-report">
              <Route
                path=""
                element={
                  <Suspense fallback={<Loader />}>
                    <LazyBYMReport />
                  </Suspense>
                }
              />
              <Route
                path="agents-report"
                element={
                  <Suspense fallback={<Loader />}>
                    <LazyBYMAgentsReport />
                  </Suspense>
                }
              />
              <Route
                path="health-policy-breakdown"
                element={
                  <Suspense fallback={<Loader />}>
                    <LazyHealthPolicyBreakDown />
                  </Suspense>
                }
              />
              <Route
                path="regional-report"
                element={
                  <Suspense fallback={<Loader />}>
                    <LazyBYMRegionalReport />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="britam-banca-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyBritamBancaReport />
                </Suspense>
              }
            />

            <Route
              path="channels-report"
              element={
                <Suspense fallback={<Loader />}>
                  <LazyChannelsReport />
                </Suspense>
              }
            />
            {sasPartners?.map((route: any, index) => {
              return (
                <Route
                  key={index}
                  path={`sas/:id`}
                  element={
                    <Suspense fallback={<Loader />}>
                      <LazyNBKReport />
                    </Suspense>
                  }
                />
              );
            })}
          </Route>
        )}
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default App;
